import React, { useState, useRef, Fragment, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCopy, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTextInput } from '../../common/custom-hook';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ASSEMBLY_PROJECT, CHANGE_PERMISSION_ASSEMBLY_PROJECT,ADD_PERMISSION_FOR_EMAILS,
  AMEND_PERMISSION_FOR_EMAIL } from '../../queries';
import { progressBarStore } from '../../context/ProgressBarContext';

type ModalSharingProps = {
  isOpen: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
  projectid: string;
};

type DropdownItemType = {
  id: number;
  text: string;
  ref: "VIEW" | "EDIT" | "COMMENT" | "DISABLED"
};

type DropdownProps = {
  items: Array<DropdownItemType>;
  selectedItem: DropdownItemType | null;
  onClick: (selection: DropdownItemType) => void;
  disabled?: boolean;
};

function MyDropdown(props: DropdownProps) {
  const { items, selectedItem, onClick, disabled } = props;


  return (
    <UncontrolledDropdown disabled={disabled}>
      <DropdownToggle caret>{selectedItem?.text ?? 'Dropdown'} {disabled && <FontAwesomeIcon icon={faSpinner} className="fa-spin" width="1em" />}</DropdownToggle>
      <DropdownMenu>
        {items
          .filter(item => item.id !== selectedItem?.id)
          .map(item => (
            <DropdownItem
              key={item.id}
              className={`${selectedItem?.id === item.id ? 'active' : null}`}
              onClick={() => onClick(item)}
            >
              {item.text} 
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
const dropdownOptions: DropdownItemType[] = [
  { id: 0, text: 'Disabled', ref: 'DISABLED' },
  { id: 1, text: 'Can view', ref: 'VIEW' },
  { id: 2, text: 'Can comment', ref: 'COMMENT' },
  { id: 3, text: 'Can edit', ref: 'EDIT' },
];
const mapShareTypeToDropdown = (share?: Permission) => {
  let option: DropdownItemType = { id: 0, text: 'Loading', ref: 'DISABLED' };
  option = dropdownOptions.find(item => item.ref === share) || option;
  return option;
};

const sanitizeEmails = (raw: string) => {
  const emails = raw.split(',');
  return emails.map(email => email.trim())
}
const validateEmailList = (raw: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emails = raw.split(',');
  let valid = true;
  for (const email of emails) {
    if (email === '' || !regex.test(email.replace(/\s/g, ''))) {
      valid = false;
    }
  }
  return valid;
}

export default function ModalSharing(props: ModalSharingProps) {
  const { toggle, isOpen, projectid } = props;
  const { pushProgress, completeProgress } = useContext(progressBarStore);

  const {
    data: assemblyProjectData,
    loading: loadingAssemblyProjectData
  } = useQuery<AssemblyProjectData, AssemblyProjectVars>(GET_ASSEMBLY_PROJECT, {
    variables: { projectId: projectid },
    onCompleted: () => completeProgress("project")
  });

  const [
    changeProjectPermission,
    { loading: loadingChangeProjectPermission, error: errorChangeProjectPermission },
  ] = useMutation<{
    changeAssemblyProjectPermission: AssemblyProject;
    permission: Permission;
    projectId: string;
  }>(CHANGE_PERMISSION_ASSEMBLY_PROJECT,
    {onCompleted: () => completeProgress("changePermission")});

  const [addPermissionForEmails, { loading: loadingAddPermissionForEmails, error: errorAddPermissionForEmails }] = useMutation<{
    addPermissionForEmails: AssemblyProject;
    privilege: Permission;
    email: string;
    projectId: string;
  }>(ADD_PERMISSION_FOR_EMAILS,
    {onCompleted: () => completeProgress("addEmailPermission")});
  const [
    amendPermissionForEmail,
    { loading: loadingAmendPermissionForEmail, error: errorAmendPermissionForEmail },
  ] = useMutation<{
    addPermissionForEmails: AssemblyProject;
    privilege: Permission;
    email: string;
    projectId: string;
  }>(AMEND_PERMISSION_FOR_EMAIL,
    {onCompleted: () => completeProgress("amendEmailPermission")});


  const selectedLinkRight = 
     mapShareTypeToDropdown(assemblyProjectData?.getAssemblyProject.permission)

  const [
    selectedTeamRight,
    setSelectedTeamRight,
  ] = useState<DropdownItemType | null>({ id: 0, text: 'Disabled', ref: "DISABLED" });

  const inputLinkRef = useRef<HTMLInputElement>(null);

  const [copied, setCopied] = useState(false);

  const projectFullUrl = `${process.env.NEXT_PUBLIC_ORIGIN}/${projectid}`
  const inputLinkRight = useTextInput(projectFullUrl);
  const inputTeamRight = useTextInput('');

  const loading = loadingAssemblyProjectData || loadingChangeProjectPermission;

  const emailListIsValid = validateEmailList(inputTeamRight.value);

  useEffect(() => {
    if (loadingAssemblyProjectData) pushProgress('project');
    return () => completeProgress('project')
  }, [loadingAssemblyProjectData]);

  const existingShares = assemblyProjectData?.getAssemblyProject?.shares || [];

    return (
      <Modal id="modal-share" isOpen={isOpen} toggle={() => toggle(!isOpen)}>
        <ModalBody>
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <span>
              <strong>Share Project </strong>
            </span>
            <span
              onClick={() => toggle(!isOpen)}
              style={{
                position: 'absolute',
                right: 0,
                color: '#828282',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faTimes} width={16} height={16} />
            </span>
          </div>

          <div>
            <div className="form-share">
              <div className="top-bar">
                <div className="top-bar__label">
                  <span>Access to anyone with the link</span>
                </div>
                <div className="top-bar__dropdown">
                  <span>
                    <MyDropdown
                      items={dropdownOptions}
                      selectedItem={selectedLinkRight}
                      onClick={selection => {
                        if (selection) {
                          pushProgress("changePermission");
                          changeProjectPermission({ variables: { permission: selection.ref, projectId: projectid } });
                        }
                      }}
                      disabled={loading}
                    />
                  </span>
                </div>
              </div>
              <div
                className="el-input"
                style={
                  copied
                    ? { backgroundColor: '#4f206d', transition: 'background 0.2s ease-in-out' }
                    : { transition: 'background 0.2s ease-in-out' }
                }
              >
                <div style={{ flex: 1 }}>
                  <input
                    ref={inputLinkRef}
                    disabled={selectedLinkRight?.id === 0}
                    readOnly={true}
                    {...inputLinkRight}
                  />
                  <span style={{ cursor: 'pointer' }}>
                    <CopyToClipboard
                      text={inputLinkRight.value}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 200);
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} width={16} height={16} />
                    </CopyToClipboard>
                  </span>
                </div>
              </div>
            </div>
            <hr />
            {/* ---------------------- */}
            <div className="form-share">
              <div className="top-bar">
                <div className="top-bar__label">
                  <span>Access rights for these users:</span>
                </div>
                <div className="top-bar__dropdown">
                  <span>
                    <MyDropdown
                      items={dropdownOptions}
                      selectedItem={selectedTeamRight}
                      onClick={setSelectedTeamRight}
                    />
                  </span>
                </div>
              </div>
              <div className="el-input">
                <div style={{ flex: 1 }}>
                  <input
                    disabled={selectedTeamRight?.id === 0}
                    placeholder="Enter comma-seperated email address..."
                    {...inputTeamRight}
                    style={
                      emailListIsValid || inputTeamRight.value.length === 0
                        ? { transition: 'background 0.2s ease-in-out' }
                        : { backgroundColor: '#4f206d', transition: 'background 0.2s ease-in-out' }
                    }
                  />
                </div>
                <div>
                  <button
                    disabled={selectedTeamRight?.id === 0 || !emailListIsValid || loadingAddPermissionForEmails}
                    onClick={() => {
                      console.log(inputTeamRight.value);
                      if (selectedTeamRight?.ref) {
                        pushProgress("addEmailPermission")
                        addPermissionForEmails({
                          variables: {
                            privilege: selectedTeamRight?.ref,
                            projectId: projectid,
                            emails: sanitizeEmails(inputTeamRight.value),
                          },
                        });
                      }
                    }}
                  >
                    Invite
                  </button>
                </div>
              </div>
              <Table className="existing-shares">
                <tbody>
                  {existingShares.length > 0 &&
                    existingShares.map(share => (
                      <tr key={share.invitee}>
                        <td>{share.invitee}</td>
                        <td className="dropdown">
                          <div className="top-bar__dropdown">
                            <span>
                              <MyDropdown
                                items={dropdownOptions}
                                selectedItem={mapShareTypeToDropdown(share.privilege)}
                                onClick={selection => {
                                  pushProgress("amendEmailPermission")
                                  amendPermissionForEmail({
                                    variables: { privilege: selection.ref, projectId: projectid, email: share.invitee },
                                  });
                                }}
                                disabled={loadingAmendPermissionForEmail}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
}
