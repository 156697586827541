import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch,  faPlus, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef } from 'react';
import { Button, Collapse } from 'reactstrap';
import { useTextInput, useTextInputSettable } from '../../common/custom-hook';
import { faTrashAlt, faFolder, faEdit } from '@fortawesome/free-regular-svg-icons';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type BinProps = {
  mediaFolders?: ProjectFolder[];
  loaded?: ImportedMedia[];
  userHasWritePermission?: boolean;
  handleChangeSelected: (media: ImportedMedia) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  openModalConfirmDeleteFolder?: (folder: ProjectFolder) => void;
};

enum ItemTypes {
  MEDIA = "MEDIA"
}

export default function Bin({
  mediaFolders,
  loaded, 
  userHasWritePermission, 
  handleChangeSelected, 
  openModalConfirmDeleteMedia,
  openModalConfirmDeleteFolder,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
}: BinProps) 
{
  const search = useTextInput('');
  const inputCreateFolder = useTextInputSettable('');

  function open(media: ImportedMedia) {
    if(openModalConfirmDeleteMedia) {
      openModalConfirmDeleteMedia(media)
    }
  }

  function createMeidaProject() {
    if(handleCreateMediaFolder && typeof handleCreateMediaFolder === "function") {
      handleCreateMediaFolder(inputCreateFolder.value, clearInputText);
    }
  }

  function clearInputText() {
    inputCreateFolder.setvalue("")
  }

  const renderMediasNotInFolder = (loaded: ImportedMedia[] | undefined) => {
    if (!loaded) return null;

    return loaded
    .filter((video) => !video.archived)
    .filter((video) => (!search.value ? true : new RegExp(search.value, 'gi').test(video.name || '')))
    .map((video, index) => (
      <DraggableMedia 
        key={'media-not-in-folder-' + index} 
        media={video} 
        userHasWritePermission={userHasWritePermission}
        handleChangeSelected={handleChangeSelected}
        open={open}
      />
    ));
  };

  const renderMediaFolders = () => {
    if (!mediaFolders) return null;

    return mediaFolders
    .map((folder, folderIdx) => (
      <DroppableFolder
        key={'folder-media-' + folderIdx}
        userHasWritePermission={userHasWritePermission}
        folder={folder}
        index={folderIdx}
        renderMediasNotInFolder={renderMediasNotInFolder}
        handleAmendMedia={handleAmendMedia}
        handleAmendMediaFolder={handleAmendMediaFolder}
        openModalConfirmDeleteFolder={openModalConfirmDeleteFolder}
      />
    ));
  };
  

  return (
    <div className="sidebar-body">
      <span style={{ fontStyle: 'italic', padding: '0 8px' }}>Choose file to load it in the player</span>
      <React.Fragment>
        <div className="input-search">
          <span className="prefix-icon">
            <FontAwesomeIcon icon={faSearch} width={16} height={16} />
          </span>
          <div style={{ width: '100%' }}>
            <input placeholder="Search list"  {...search}/>
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="input-create-folder">
          <span onClick={createMeidaProject} className="prefix-icon">
            <FontAwesomeIcon icon={faPlus} width={16} height={16} />
          </span>
          <div style={{ width: "100%" }}>
            <input
              placeholder="Create new folder"
              onKeyDown={e => e.key === 'Enter' && createMeidaProject()}
              value={inputCreateFolder.value}
              onChange={inputCreateFolder.onChange}
            />
          </div>
        </div>
      </React.Fragment>
      <div className="sidebar-media">
        {!loaded && (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} /> Loading
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          <DroppableOutsideFolder 
            handleAmendMedia={handleAmendMedia}
          >
            { renderMediaFolders() }
            { renderMediasNotInFolder(loaded) }
          </DroppableOutsideFolder>
        </DndProvider>
      </div>
    </div>
  );
}


function DraggableMedia({ 
  media, 
  userHasWritePermission, 
  handleChangeSelected, 
  open
}: any) 
{
  const [collectedProps, drag] = useDrag({
    type: ItemTypes.MEDIA,
    item: { media }
  })

  return (
    <div ref={drag} className="sidebar-media__item" title={media.name}>
      <Button 
        color="link" 
        onClick={() => handleChangeSelected(media)} 
        title={media.name}
        style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
      >
        {media.name}
      </Button>
      {
        (userHasWritePermission) && 
        <Button onClick={() => open(media)} className="btn-trash">
          <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} />
        </Button>
      }
    </div>
  )
}

function DroppableFolder({ 
  folder, 
  userHasWritePermission, 
  handleAmendMediaFolder, 
  renderMediasNotInFolder, 
  handleAmendMedia, 
  openModalConfirmDeleteFolder  
}: any) 
{
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if(didDrop) return;
      if(media.project_folder_id === folder.id) return;

      handleAmendMedia(media.id, folder.id)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  })

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputFolderName = useTextInput(folder.name);

  const toggle = () => setIsOpen(!isOpen);
  const toggleEdit = (e: any) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  }


  function onSaveFolderName() {
    if(
      handleAmendMediaFolder && 
      typeof handleAmendMediaFolder === "function" &&
      inputFolderName.value !== folder.name
    ) {
      handleAmendMediaFolder(folder.id, inputFolderName.value)
    }
    setIsEditing(!isEditing);
  }

  return (
    <div ref={drop} className={`sidebar-media__folder ${isOver ? "over" : ""} ${isOpen ? "active" : ""}`} title={folder.name}>
      <Button onClick={toggle} color="link" title={folder.name}>
        <span style={{paddingRight: 4}}>
          <FontAwesomeIcon icon={faFolder} width={18} height={18} />
        </span>
        <span>
          {
            !isEditing && folder.name
            || 
            <input
              autoFocus
              onClick={e => e.stopPropagation()}
              onBlur={onSaveFolderName}
              {...inputFolderName}
            />
          }
        </span>
        <span>
          { 
            !isEditing && (
              !isOpen &&  <FontAwesomeIcon icon={faCaretRight} width={18} height={18} /> 
              ||  <FontAwesomeIcon icon={faCaretDown} width={18} height={18} /> 
            )
          }
        </span>
        <span className="sidebar-media__folder-tool">
          <span onClick={toggleEdit}>
            <FontAwesomeIcon icon={faEdit} width={16} height={16} /> 
          </span>
          {
            userHasWritePermission && 
            <span onClick={(e) => {
              e.stopPropagation();
              openModalConfirmDeleteFolder(folder);
            }}>
              <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} /> 
            </span>
          }
        </span>
      </Button>

      <Collapse isOpen={isOpen}>
        { renderMediasNotInFolder(folder.imported_media) }
      </Collapse>
    </div>
  )
}

function DroppableOutsideFolder({ handleAmendMedia, children }: any) {
  const [{isOver}, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if(didDrop) return;
      if(media.project_folder_id === null) return;

      handleAmendMedia(media.id, null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true })
    }),
  })

  return (
    <div ref={drop} className={`sidebar-media__outside-folder ${isOver ? "over" : ""}`}>
      { children }
    </div>
  )
}
