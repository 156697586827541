import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type ModalConfirmProps = {
  isOpen: boolean;
  message?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  swapActive?: boolean;
  renderFooter?: () => JSX.Element;
  toggle: () => void;
  confirm?: () => void;
  cancel?: () => void;
};

export default function ModalConfirm(props: ModalConfirmProps) {
  const { isOpen, title, message, confirmText, cancelText, swapActive, renderFooter, toggle, confirm, cancel } = props;

  return (
    <Modal className="modal-confirm" isOpen={isOpen} toggle={toggle}>
      {title && (
        <ModalHeader className="modal-confirm__header" toggle={toggle}>
          {title}
        </ModalHeader>
      )}
      <ModalBody className="modal-confirm__body">{message}</ModalBody>
      <ModalFooter className="modal-confirm__footer">
        {confirm && <Button className={`btn-ok ${swapActive ? "active" : ""}`} onClick={confirm}>{confirmText ?? "Yes"}</Button>}
        {cancel && <Button className={`btn-cancel ${!swapActive ? "active" : ""}`} onClick={cancel}>{cancelText ?? "No"}</Button>}
        {
          renderFooter && renderFooter()
        }
      </ModalFooter>
    </Modal>
  );
}
