import React, { useState, useEffect, useCallback, useRef } from 'react';

export function useTextInput(
  defaultValue: any,
  validate?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean,
  callback?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
) {
  const [value, setValue] = useState(defaultValue);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const isValid: boolean = typeof validate !== 'undefined' ? validate(e) : true;
    setValidation(isValid);

    if (isValid) setValue(e.target.value);
    if (callback) callback(e);
  }

  return { value, onChange };
}
export function useTextInputSettable(
  defaultValue: any,
  validate?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean,
  callback?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
) {
  const [value, setValue] = useState(defaultValue);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const isValid: boolean = typeof validate !== 'undefined' ? validate(e) : true;
    setValidation(isValid);

    if (isValid) setValue(e.target.value);
    if (callback) callback(e);
  }
  function setvalue(value: any) {
    setValue(value);
  }

  return { value, onChange, setvalue };
}

export function useDebouncedEffect(effect: () => any, delay: number, deps: Array<any>) {
  const callback = useCallback(effect, deps);
  const isMounted = useRef(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      if(isMounted.current) callback();
      else isMounted.current = true;
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
}
