import React, { useEffect, useState } from 'react';
import { Tooltip } from 'reactstrap';
import * as Popper from 'popper.js';

type ToolTipProps = {
  isOpen: boolean;
  element: React.RefObject<HTMLElement>
  placement?: Popper.Placement;
};

export default function CustomToolTip({ isOpen, element, placement }: ToolTipProps) {
  if (!isOpen || element.current === null) return null;
  return (
    <Tooltip placement={placement} isOpen={isOpen} target={element}>
      Width and height must both be even numbers
    </Tooltip>
  );
}

CustomToolTip.defaulProps = {
  placement: 'auto',
};
