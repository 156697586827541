import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { Button, Input, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFolder,
  faCaretDown,
  faCaretUp,
  faSpinner,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation, gql, NetworkStatus } from '@apollo/client';
import { useTextInput } from '../../common/custom-hook';
import { progressBarStore } from '../../context/ProgressBarContext';

momentDurationFormatSetup(moment);

interface SimonSaysProjectData {
  getSimonSaysProject: SimonSaysProject;
}
interface SimonSaysProjectsData {
  getSimonSaysProjects: SimonSaysProject[];
}

interface SimonSaysDirectoriesData {
  getDirectories: SimonSaysDirectory[];
}

interface SimonSaysProjectVars {
  projectId: string;
}

const GET_DIRECTORIES_QUERY = gql`
  query getSimonSaysDirectories {
    getDirectories(onlyTranscriptionAvailable: true) {
      id
      name
      projects {
        id
        name
        media {
          id
          name
          durationInSeconds
        }
      }
    }
  }
`;

const GET_PROJECTS_QUERY = gql`
  query getSimonSaysProjects {
    getSimonSaysProjects(onlyTranscriptionAvailable: true, directoryId: null) {
      id
      name
      media {
        id
        name
        durationInSeconds
      }
    }
  }
`;

const IMPORT_SIMONSAYS_PROJECT = gql`
  mutation importSimonSaysProjectMediaIntoAssemblyProject($simonSaysProjectId: ID!, $projectId: ID!) {
    importSimonSaysProjectMediaIntoAssemblyProject(simonSaysProjectId: $simonSaysProjectId, projectId: $projectId) {
      id
      name
      imported_media {
        id
        name
        url
      }
    }
  }
`;

const IMPORT_SIMONSAYS_MEDIA = gql`
  mutation importMediaIntoAssemblyProject($mediaId: ID!, $projectId: ID!) {
    importMediaIntoAssemblyProject(mediaId: $mediaId, projectId: $projectId) {
      id
      name
      imported_media {
        id
        name
        url
      }
    }
  }
`;

let projects: SimonSaysProject[] | undefined;
let directories: SimonSaysDirectory[] | undefined;

type ImportProps = {
  onImportSuccesful: (data: ImportSuccesfulData) => void;
  projectId?: string;
};

const Import = ({ onImportSuccesful, projectId }: ImportProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [isOpenDirctiories, setIsOpenDirctiories] = useState<{ [key: string]: boolean }>({});
  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const search = useTextInput('');

  const {
    loading: loadingProjects,
    data: userProjectsData,
    error: errorLoadingProjects,
    refetch: refetchProjects,
    networkStatus: networkStatusProjects,
  } = useQuery<SimonSaysProjectsData, never>(GET_PROJECTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => completeProgress('loadingProjects'),
  });

  const {
    loading: loadingDirectoriesWithProjects,
    data: userDirectoriesWithProjectsData,
    error: errorLoadingDirectoriesWithProjects,
    refetch: refetchDirectoriesWithProjects,
    networkStatus: networkStatusDirectoriesWithProjects,
  } = useQuery<SimonSaysDirectoriesData, never>(GET_DIRECTORIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => completeProgress('loadingDirectoriesWithProjects'),
  });

  const [importSimonSaysProject, { loading: importing, error: errorImporting }] = useMutation<{
    importSimonSaysProject: AssemblyProject;
  }>(IMPORT_SIMONSAYS_PROJECT, {
    onCompleted: data => {
      completeProgress('importing');
      onImportSuccesful(data);
    },
  });

  const [importSimonSaysMedia, { loading: importingMedia, error: errorImportingMedia }] = useMutation<{
    importSimonSaysProject: AssemblyProject;
  }>(IMPORT_SIMONSAYS_MEDIA, {
    onCompleted: data => {
      completeProgress('importingMedia');
      onImportSuccesful(data);
    },
  });

  useEffect(() => {
    if (!loadingProjects) {
      refetchProjects();
    }
    return () => completeProgress('loadingProjects');
  }, [completeProgress, loadingProjects, refetchProjects]); //not adding comprehensive list as we only want to call this at start

  useEffect(() => {
    if (!loadingDirectoriesWithProjects) {
      refetchDirectoriesWithProjects();
    }
    return () => completeProgress('loadingDirectoriesWithProjects');
  }, [completeProgress, loadingDirectoriesWithProjects, refetchDirectoriesWithProjects]); //not adding comprehensive list as we only want to call this at start

  useEffect(() => {
    if (importing) pushProgress('importing');
  }, [importing, pushProgress]);

  useEffect(() => {
    if (importingMedia) pushProgress('importingMedia');
  }, [importingMedia, pushProgress]);

  useEffect(() => {
    if (loadingDirectoriesWithProjects) pushProgress('loadingDirectoriesWithProjects');
  }, [loadingDirectoriesWithProjects, pushProgress]);

  useEffect(() => {
    if (loadingProjects) pushProgress('loadingProjects');
  }, [loadingProjects, pushProgress]);

  useEffect(() => {
    const directories = userDirectoriesWithProjectsData?.getDirectories;
    const ids = directories?.map(directory => ({ [directory.id]: false }));
    setIsOpenDirctiories((prevState: any) => ({ ...ids, ...prevState }));
  }, [userDirectoriesWithProjectsData]);

  projects = userProjectsData?.getSimonSaysProjects;
  directories = userDirectoriesWithProjectsData?.getDirectories;

  const handleProjectClicked = async (simonSaysProjectId: string) => {
    if (projectId) {
      importSimonSaysProject({ variables: { simonSaysProjectId, projectId } });
    }
  };

  const handleMediaClicked = async (mediaId: string) => {
    console.log(mediaId);
    if (projectId) {
      importSimonSaysMedia({ variables: { mediaId, projectId } });
    }
  };

  function toggleDirectory(directory: SimonSaysDirectory) {
    const isOpen = isOpenDirctiories[directory.id];
    setIsOpenDirctiories(prevState => ({ ...prevState, [directory.id]: !isOpen }));
  }

  const renderAllProjects = () => {
    if (!isAuthenticated || !projects) return null;

    return projects
      .filter((proj: SimonSaysProject) => (!search.value ? true : new RegExp(search.value, 'gi').test(proj.name || '')))
      .map((proj: SimonSaysProject) => (
        <li className="sidebar-item" title={proj.name} key={proj.id}>
          <Button className="sidebar-item__project" color="link" onClick={() => handleProjectClicked(proj.id)}>
            {proj.name}
          </Button>
          <div>
            {proj.media.map(media => (
              <Button
                title={media.name}
                className="sidebar-item__media"
                key={media.id}
                color="link"
                onClick={() => handleMediaClicked(media.id)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>- {media.name}</div>
                  <div style={{ flex: 1, marginLeft: 8, textAlign: 'right' }}>
                    {moment.duration(media.durationInSeconds, 'seconds').format('hh:mm:ss')}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </li>
      ));
  };

  const renderProjectByDirectory = (directory: SimonSaysDirectory) => {
    if (!isAuthenticated || !directory.projects) return null;

    return directory.projects.map((proj: SimonSaysProject) => (
      <li className="sidebar-item" title={proj.name} key={proj.id}>
        <Button className="sidebar-item__project" color="link" onClick={() => handleProjectClicked(proj.id)}>
          {proj.name}
        </Button>
        <div>
          {proj.media &&
            proj.media.map(media => (
              <Button
                title={media.name}
                className="sidebar-item__media"
                key={media.id}
                color="link"
                onClick={() => handleMediaClicked(media.id)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>- {media.name}</div>
                  <div style={{ flex: 1, marginLeft: 8, textAlign: 'right' }}>
                    {moment.duration(media.durationInSeconds, 'seconds').format('hh:mm:ss', { trim: false })}
                  </div>
                </div>
              </Button>
            ))}
        </div>
      </li>
    ));
  };

  const refetching =
    networkStatusProjects === NetworkStatus.refetch || networkStatusDirectoriesWithProjects === NetworkStatus.refetch;

  return (
    <React.Fragment>
      <React.Fragment>
        {!isAuthenticated && !isLoading && (
          <div style={{ margin: '0 25px' }}>Log in to see your Simon Says projects</div>
        )}
      </React.Fragment>
      {isAuthenticated && (
        <div className="sidebar-body">
          <span style={{ fontStyle: 'italic', padding: '0 8px', fontSize: 12 }}>
            <span>Import transcribed projects to video edit</span> ||{' '}
            <span>
              <a style={{ color: 'inherit' }} href="https://app.simonsays.ai/app" target="_blank">
                Launch transcription mode <FontAwesomeIcon icon={faExternalLinkAlt} width={10} height={10} />
              </a>
            </span>
            {!isAuthenticated && isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="ml-2" width={16} height={16} />
            )}
            {isAuthenticated && (loadingProjects || importing || refetching) && (
              <FontAwesomeIcon icon={faSpinner} spin className="ml-2" width={16} height={16} />
            )}
          </span>
          <React.Fragment>
            <div className="input-search">
              <span className="prefix-icon">
                <FontAwesomeIcon icon={faSearch} width={16} height={16} />
              </span>
              <div style={{ width: '100%' }}>
                <input placeholder="Search list" {...search} />
              </div>
            </div>
          </React.Fragment>
          <ul>
            <React.Fragment>
              <div className="sidebar-project">
                <React.Fragment>
                  {directories &&
                    directories?.map(directory => (
                      <React.Fragment key={directory.id}>
                        <span onClick={() => toggleDirectory(directory)}>
                          <span className="project-folder">
                            <span style={{ position: 'relative', top: -2 }}>
                              <FontAwesomeIcon icon={faFolder} height={24} />
                            </span>
                            <Button className="project-folder__name">{directory.name}</Button>
                            <span className="project-folder__icon">
                              <FontAwesomeIcon
                                icon={isOpenDirctiories[directory.id] ? faCaretUp : faCaretDown}
                                height={16}
                                width={16}
                              />
                            </span>
                          </span>
                          <Collapse isOpen={isOpenDirctiories[directory.id]}>
                            {renderProjectByDirectory(directory)}
                          </Collapse>
                        </span>
                      </React.Fragment>
                    ))}
                </React.Fragment>

                <React.Fragment>{renderAllProjects()}</React.Fragment>
              </div>
            </React.Fragment>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default Import;
